<template>
	<div class="app-main__inner">
		<div class="container mb-3 dm-sans-font">
			<div
				class="flex flex-col md:flex-row justify-between align-items-center mb-3"
			>
				<div>
					<div
						class="flex justify-between align-items-center space-x-3 mb-3"
					></div>
				</div>
				<div>
					<span
						class="text-base font-semibold text-blue-900 cursor-pointer"
						@click="$router.go(-1)"
					>
						{{ $t("back") }}</span
					>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<div class="bg-white">
						<div class="flex head border-b p-2 row">
							<span
								class="col-md-6 text-xl col-span-1 font-semibold text-gray-900"
								>{{ $t("deliveryDetails") }}</span
							>
							<span
								class="col-md-6 col-span-1 font-15 select-address-div"
								v-if="locationList.length > 0"
							>
								{{ $t("deliveryAddressOptions") }}
								<el-select
									:placeholder="`${$t('savedAddress')}`"
									size="mini"
									filterable
									clearable
									:remote-method="searchAddress"
									remote
									loading-text="loading"
									v-model="searchName"
									@change="autoFillBillingInfo($event)"
								>
									<el-option
										v-for="item in locationList"
										:key="item.id"
										:label="item.location_name"
										:value="item.id"
									>
									</el-option>
								</el-select>
								<!--								<select class="select-address">-->
								<!--									<option value="">{{ $t('deliveryAddressOptions') }}</option>-->
								<!--									<option v-for="(item,key) in locationList" :key="key"-->
								<!--											:value="item.id">{{ item.location_name }}</option>-->
								<!--								</select>-->
							</span>
						</div>
						<form
							class="row p-2"
							@submit.prevent="checkCompanySettings"
						>
							<div class="col-md-6">
								<div class="form-group">
									<label for="exampleEmail" class="text-sm"
										>{{ $t("deliveryName") }}
										<!--										<span-->
										<!--										class="help-tooltip cursor-pointer" title="Tooltip Sample"></span>-->
									</label>
									<div>
										<input
											v-model="delivery.name"
											type="text"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="exampleEmail" class="text-sm"
										>{{ $t("deliveryAddress") }}
										<!--										<span-->
										<!--										class="help-tooltip cursor-pointer" title="Tooltip Sample"></span>-->
									</label>
									<div>
										<input
											v-model="delivery.address"
											type="text"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="exampleEmail" class="text-sm"
										>{{ $t("deliveryZipCode") }}
										<!--										<span-->
										<!--										class="help-tooltip cursor-pointer" title="Tooltip Sample"></span>-->
									</label>
									<div>
										<input
											v-model="delivery.zipCode"
											type="text"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="exampleEmail" class="text-sm"
										>{{ $t("deliveryCountry") }}
										<!--										<span-->
										<!--										class="help-tooltip cursor-pointer" title="Tooltip Sample"></span>-->
									</label>
									<div>
										<!--										<select v-model="delivery.country"-->
										<!--												class=" flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">-->
										<!--											<option v-for="(item,key) in countries" :key="key" :value="item.key">-->
										<!--												{{ item.name }}-->
										<!--											</option>-->
										<!--										</select>-->
										<el-select
											v-model="delivery.country"
											filterable
											:placeholder="$t('select')"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border rounded-md text-gray-500 w-full"
										>
											<el-option
												v-for="(item, key) in countries"
												v-bind:key="key"
												:label="item.name"
												:value="item.key"
											>
											</el-option>
										</el-select>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label for="exampleEmail" class="text-sm"
										>{{ $t("deliveryCity") }}
										<!--										<span-->
										<!--										class="help-tooltip cursor-pointer" title="Tooltip Sample"></span>-->
									</label>
									<div>
										<input
											v-model="delivery.city"
											type="text"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										/>
									</div>
								</div>
							</div>
							<div class="col-md-6 hidden">
								<div class="form-group">
									<label class="text-sm"
										>{{ $t("email") }}
										<!--										<span-->
										<!--										class="help-tooltip cursor-pointer" title="Tooltip Sample"></span>-->
									</label>
									<div>
										<input
											v-model="delivery.email"
											type="email"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										/>
									</div>
								</div>
							</div>
							<!--							<div class="col-md-6">-->
							<!--								<div class="form-group">-->
							<!--									<label for="exampleEmail" class="text-sm">{{ $t('ourReference') }}-->
							<!--&lt;!&ndash;										<span&ndash;&gt;-->
							<!--&lt;!&ndash;										class="help-tooltip cursor-pointer" title="Tooltip Sample"></span>&ndash;&gt;-->
							<!--									</label>-->
							<!--									<div>-->
							<!--										<input v-model="delivery.reference" type="text"-->
							<!--											   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">-->
							<!--									</div>-->
							<!--								</div>-->
							<!--							</div>-->
							<div class="col-md-6">
								<div class="form-group">
									<label for="exampleEmail" class="text-sm"
										>{{ $t("message")
										}}<span
											class="help-tooltip cursor-pointer"
											:title="$t('checkoutMessage')"
										></span
									></label>
									<div>
										<textarea
											v-model="delivery.message"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										></textarea>
									</div>
								</div>
							</div>
							<div
								class="col-md-6"
								v-for="(
									item, key
								) in GET_COMPANY_CHECKOUT_CONFIG"
								:key="key"
							>
								<div class="form-group">
									<label for="exampleEmail" class="text-sm">{{
										item.field_title
									}}</label>
									<div>
										<input
											:name="item.field_map"
											:id="item.field_map"
											type="text"
											class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
										/>
									</div>
								</div>
							</div>
							<div class="col-md-12">
								<div class="form-group">
									<label for="exampleEmail" class="text-sm"
										><input
											v-model="useBilling"
											@change="prefillBillingInfo"
											type="checkbox"
											class="override-form-field-l"
										/>
										{{ $t("sameBillingAddress") }}</label
									>
								</div>
							</div>
							<div class="col-md-12 mt-5 mb-4">
								<div
									class="flex justify-center"
									v-if="CART_PRODUCT.length > 0"
								>
									<button
										:disabled="processing"
										type="submit"
										class="flex justify-center align-items-center space-x-4 btn-custom focus:outline-none border px-3 py-2 rounded-md text-white font-semibold w-11/12 md:w-1/3"
									>
										<span
											v-if="processing"
											class="flex align-items-center space-x-3"
										>
											<svg
												class="animate-spin mr-2 h-5 w-5 text-white"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
											>
												<circle
													class="opacity-25"
													cx="12"
													cy="12"
													r="10"
													stroke="currentColor"
													stroke-width="4"
												></circle>
												<path
													class="opacity-75"
													fill="currentColor"
													d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
												></path>
											</svg>
											{{ $t("pleaseWait") }}
										</span>
										<span v-else>{{
											$t("placeOrder")
										}}</span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="col-md-6">
					<div class="bg-white">
						<div class="head border-b p-2">
							<span class="text-xl font-semibold text-gray-900">{{
								$t("orderSummary")
							}}</span>
						</div>
						<div class="p-2 flex flex-col">
							<div class="cart-listing-scrollsupport checkout">
								<div
									class="flex flex-col mb-3"
									v-for="product in CART_PRODUCT"
									:key="product.id"
								>
									<CheckoutComponent :product="product" />
								</div>
							</div>

							<div class="flex flex-col space-y-3">
								<div class="flex justify-between">
									<span class="text-gray-900 text-sm"
										>{{ $t("totalAmountExVat") }}
									</span>
									<span
										>{{
											Number(
												getTotalPriceExVat()
											)?.toFixed(2)
										}}
										<span v-if="CART_PRODUCT.length > 0">{{
											CART_PRODUCT[0].currency
										}}</span
										><span v-else>kr</span></span
									>
								</div>
								<div class="flex justify-between">
									<span class="text-gray-900 text-sm"
										>{{ $t("vatAmount") }}
									</span>

									<span
										>{{ Number(getVatAmount())?.toFixed(2)
										}}<span
											v-if="CART_PRODUCT.length > 0"
											>{{
												CART_PRODUCT[0].currency
											}}</span
										><span v-else>kr</span></span
									>
								</div>
								<div class="flex justify-between">
									<span
										class="text-gray-900 text-sm font-semibold"
										>{{ $t("totalAmountIncVat") }}
									</span>
									<span>
										{{
											Number(
												getTotalPriceIncVat()
											).toFixed(2)
										}}

										<span v-if="CART_PRODUCT.length > 0">{{
											CART_PRODUCT[0].currency
										}}</span
										><span v-else>kr</span></span
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CheckoutComponent from "./component/CheckoutComponent";
import {
	convertDiscountValueToPercentage,
	get_vat_amount,
} from "../../utils/utils";
import { toRaw } from "vue";

export default {
	name: "checkout",
	components: { CheckoutComponent },
	data() {
		return {
			convertDiscountValueToPercentage,
			delivery: {
				name: "",
				address: "",
				zipCode: "",
				city: "",
				country: "SE",
				email: "",
				reference: "",
				orderNumber: "",
				message: "",
			},
			bankIdNumber: "",
			useBilling: false,
			searchName: "",
			processing: false,
			check: false,
			cancelBtn: false,
			openBankId: false,
			bankIDinfo: {},
			companyID: null,
			configList: [],
			locationList: [],
			countries: [],
		};
	},
	computed: {
		...mapGetters({
			GET_ARTICLES: "customer/GET_ARTICLES",
			CART_PRODUCT: "customer/CART_PRODUCT",
			CART_AMOUNT: "customer/CART_AMOUNT",
			GET_BILLING_INFO: "customer/GET_BILLING_INFO",
			GET_COUNTRY: "customer/GET_COUNTRY",
			GET_BANK_ID: "customer/GET_BANK_ID",
			CART_AMOUNT_INC_VAT: "customer/CART_AMOUNT_INC_VAT",
			CART_INC_VAT_AMOUNT: "customer/CART_INC_VAT_AMOUNT",
			GET_COMPANY_SETTING: "customer/GET_COMPANY_SETTINGS",
			GET_AUTH_USER: "portal/GET_AUTH_USER",
			GET_COMPANY_CHECKOUT_CONFIG: "customer/GET_COMPANY_CHECKOUT_CONFIG",
			GET_LOCALE: "utility/GET_LOCALE_LANG",
		}),
	},
	watch: {
		GET_BANK_ID: function (va, old) {
			const check = window.localStorage.getItem("__checkrun__");
			if (va && !check) {
				this.processDeliveryInfo();
			}
		},
		// GET_ARTICLES : function (va, old) {
		//     console.log('n', va)
		//     console.log('old', old)
		// }
	},
	beforeDestroy() {
		window.Bus.$off("get-bank-id");
	},
	mounted() {
		const { company_id } = this.$route.params;
		if (!company_id && company_id === "invalid") {
			this.$store.commit("customer/CLEAR_CART");
			this.$router.replace({ name: "customerdash" });
		} else {
			this.companyID = company_id;
			if (!this.GET_ARTICLES.total_pages) {
				this.getArticles(company_id, 20);
			}
		}
		this.getUserBilling();
		this.getCompanyInfo(company_id);
		this.getCustomerDeliveryAddress();
		this.countries = this.$services.helpers.getCountryList();
		window.Bus.$once("get-bank-id", (e) => {
			this.bankIdNumber = e.number;
			// const check = window.localStorage.getItem('__checkrun__')
			// console.log('what is:', this.check)
			this.check = true;
		});

		window.Bus.$on("dispatch-order-request", (e) => {
			this.bankIDinfo = e;
			this.processDeliveryInfo();
		});
		window.Bus.$on("switch-order-processing", (_) => {
			this.processing = !this.processing;
		});
	},
	beforeRouteUpdate(to, from, next) {
		// react to route changes...
		// don't forget to call next()
		next();
	},
	methods: {
		getVatAmount() {
			let vatAmount = 0;
			const allProducts = this.CART_PRODUCT;
			allProducts.forEach((item) => {
				const prices_given_ink_vat =
					this.GET_COMPANY_SETTING?.prices_given_ink_vat;
				const vat = item?.vat_rate;
				const delivered_quantity = item?.qty;
				const discountedPrice = item?.has_discount
					? item?.discounted_price
					: item?.sales_price;
				const vatAmountFromFunc = get_vat_amount(
					prices_given_ink_vat,
					vat,
					delivered_quantity,
					discountedPrice
				);
				vatAmount += Number(vatAmountFromFunc);
			});

			return Number(vatAmount?.toFixed(2));
		},
		getTotalPriceIncVat() {
			let totalPriceIncVat = 0;

			const allProducts = this.CART_PRODUCT;
			const prices_given_ink_vat =
				this.GET_COMPANY_SETTING?.prices_given_ink_vat;
			allProducts.forEach((item) => {
				const delivered_quantity = Number(item?.qty);
				const vat_in_percent = item?.vat_rate
					? Number(item?.vat_rate) / 100
					: 0;
				const vat_factor = 1 + vat_in_percent;

				let mainPrice = 0;
				if (item?.has_discount) {
					mainPrice = Number(item?.discounted_price);
				} else {
					mainPrice = Number(item?.sales_price);
				}

				const total_product_price = mainPrice * delivered_quantity;
				const price_inc_vat = prices_given_ink_vat
					? total_product_price
					: total_product_price * vat_factor;

				totalPriceIncVat += price_inc_vat;
			});
			return Number(totalPriceIncVat);
		},

		getTotalPriceExVat() {
			return this.getTotalPriceIncVat() - this.getVatAmount();
		},

		applyDiscount(price, discount, isPercent = true) {
			if (isPercent) {
				let discountAmount = price * (discount / 100);
				let discountedPrice = price - discountAmount;
				return discountedPrice.toFixed(2);
			} else {
				return Number(price) - Number(discount);
			}
		},
		cleanData: function (name) {
			if (["nan"].includes(name)) {
				return "";
			} else {
				return name;
			}
		},
		getCompanyInfo(id) {
			const payload = {
				id: id,
			};
			this.$store.dispatch("customer/getCompanySettings", payload);
			this.$store.dispatch("customer/getCompanyCheckoutConfig", {
				URL: `?company_id=${id}`,
			});
		},
		getUserBilling() {
			this.$store
				.dispatch("customer/getUserBillingInformation", {
					data: `?company_id=${this.companyID}`,
				})
				.then((_) => {})
				.catch((_) => {});
		},
		searchAddress(query) {
			if (query !== "") {
				this.$store
					.dispatch("customer/getCustomerDeliveryAddress", {
						URL: `?company_id=${this.companyID}&is_paging=false&search=${query}`,
					})
					.then((res) => {
						this.locationList = res.data.data.results;
					})
					.catch((err) => {
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_CUS", null);
							window.Bus.$emit("sign-out");
						}
					});
			} else {
				this.$store
					.dispatch("customer/getCustomerDeliveryAddress", {
						URL: `?company_id=${this.companyID}&is_paging=false`,
					})
					.then((res) => {
						this.locationList = res.data.data.results;
					})
					.catch((err) => {
						if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_CUS", null);
							window.Bus.$emit("sign-out");
						}
					});
			}
		},
		getCountry() {
			this.$store.dispatch("customer/getCountry");
		},
		confirmBankId() {
			window.localStorage.removeItem("__checkrun__");
			window.Bus.$emit("open-bank-id");
		},
		checkCompanySettings() {
			// this.getCompanyInfo(this.companyID)
			this.processing = true;
			if (
				this.delivery.name === "" ||
				this.delivery.address === "" ||
				this.delivery.zipCode === "" || // this.delivery.reference === ''
				this.delivery.city === ""
			) {
				this.$services.helpers.notification(
					this.$t("fieldRequired"),
					"error",
					this
				);
				this.processing = false;
				return false;
			} else if (this.CART_PRODUCT.length < 1) {
				this.processing = false;
				return this.$services.helpers.notification(
					this.$t("cartEmpty"),
					"error",
					this
				);
			}
			if (this.GET_BILLING_INFO[0].apply_general_max_credit === true) {
				if (
					parseFloat(this.CART_INC_VAT_AMOUNT) >
					parseFloat(this.GET_BILLING_INFO[0].max_credit_limit)
				) {
					this.$services.helpers.notification(
						this.$t("max_order_limit_exceeded"),
						"error",
						this
					);
					this.processing = false;
					return false;
				}
			}
			const percentage = convertDiscountValueToPercentage(
				this.CART_PRODUCT[0]?.discount_value,
				this.CART_PRODUCT[0]?.discount_type,
				this.CART_PRODUCT[0]?.sales_price
			);
			if (percentage) {
				localStorage?.setItem(
					"CURRENT_DISCOUNT_VALUE_B2B",
					`${percentage} %`
				);
			}

			this.$store
				.dispatch("customer/getCompanySettings", { id: this.companyID })
				.then((_) => {
					if (
						parseFloat(
							this.GET_COMPANY_SETTING.minimum_order_amount
						) > 0
					) {
						if (
							parseFloat(
								this.GET_COMPANY_SETTING.minimum_order_amount
							) > parseFloat(this.CART_AMOUNT)
						) {
							this.$services.helpers.notification(
								`${this.$t("minimum_order_amount_error")} ${
									this.GET_COMPANY_SETTING
										.minimum_order_amount
								}`,
								"error",
								this
							);
							this.processing = false;
							return false;
						}
					}
					if (
						this.GET_COMPANY_SETTING
							.require_bank_id_for_all_orders === false &&
						this.GET_COMPANY_SETTING
							.require_bank_id_for_for_orders_above_certain_amount ===
							false
					) {
						this.processDeliveryInfo();
					} else if (
						this.GET_COMPANY_SETTING
							.require_bank_id_for_all_orders === true
					) {
						this.confirmBankId();
					} else if (
						this.GET_COMPANY_SETTING
							.require_bank_id_for_for_orders_above_certain_amount ===
						true
					) {
						if (
							parseFloat(this.CART_INC_VAT_AMOUNT) >
							parseFloat(
								this.GET_COMPANY_SETTING.bankid_order_amount
							)
						) {
							this.confirmBankId();
						} else {
							this.processDeliveryInfo();
						}
					} else {
						// this.processDeliveryInfo()
						this.processing = false;
						this.$services.helpers.notification(
							this.$t("error_processing_request"),
							"error",
							this
						);
					}
				})
				.catch((err) => {
					this.processing = true;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_CUS", null);
						window.Bus.$emit("sign-out");
					} else {
						this.$services.helpers.notification(
							err.response.data.message,
							"error",
							this
						);
					}
				});
		},
		getArticles(data, limit) {
			this.loading = true;
			this.$store
				.dispatch("customer/getArticles", {
					pageUrl: `?company_id=${data}&limit=${limit}`,
				})
				.then((res) => {
					this.loading = false;
				})
				.catch((err) => {
					this.loading = false;
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_CUS", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		processDeliveryInfo() {
			this.processing = true;

			const row = [];
			this.CART_PRODUCT.forEach((element) => {
				const obj = {};
				obj.ArticleNumber = element.article_number;
				obj.DeliveredQuantity = `${element.qty}`;
				obj.Description = element.description;
				obj.VAT = element.vat_rate;
				// obj.Discount = 0;
				if (element.hasOwnProperty("extra_item")) {
					obj.extra_item = element.extra_item;
				}
				if (element.hasOwnProperty("apply_discount")) {
					if (element.apply_discount === true) {
						obj.Price = element.discounted_price;
						// obj.Discount = element.discounted_price;
					}
				}
				row.push(obj);
			});

			const payload = {
				delivery_address: this.delivery.address,
				delivery_name: this.delivery.name,
				delivery_city: this.delivery.city,
				delivery_country: this.delivery.country,
				// email: this.delivery.email,
				delivery_zipcode: this.delivery.zipCode,
				shop_id: this.companyID,
			};

			if (this.delivery.message) {
				payload["message"] = this.delivery.message;
			}
			let personal_number = this.GET_BANK_ID || "";
			if (personal_number) {
				payload.personal_number = personal_number;
			}
			if ("signature" in this.bankIDinfo) {
				if (
					this.bankIDinfo.signature !== "" &&
					this.bankIDinfo.signature !== undefined &&
					this.bankIDinfo.signature !== null
				) {
					payload.signature = this.bankIDinfo.signature;
					payload.bank_id_name = this.bankIDinfo.name;
					payload.is_signed = true;
				}
			}
			this.GET_COMPANY_CHECKOUT_CONFIG.map((item) => {
				payload[item.field_map] = document.getElementById(
					item.field_map
				).value;
			});
			if (payload.delivery_name) {
				const loading = this.$loading({
					lock: true,
					text: this.$t("processingOrder"),
					spinner: "el-icon-loading",
					background: "rgba(255,255,255,0.7)",
				});
				payload.order_row = row;
				payload.total_amount = this.CART_INC_VAT_AMOUNT;
				payload.total_excl_amount = parseFloat(
					this.CART_INC_VAT_AMOUNT - this.CART_AMOUNT
				).toFixed(2);
				this.$store
					.dispatch("customer/processDeliveryData", { payload })
					.then((res) => {
						this.check = true;
						window.localStorage.setItem("__checkrun__", true);
						this.$store.commit("customer/STORE_BANK_ID", null);
						this.processing = false;
						this.bankIDinfo = {};
						loading.close();
						const saveDetails = {
							order_number: this.delivery.orderNumber,
							amount: res.data.data.amount,
							email: res.data.data.email,
							data: res.data.data,
							order_confirmation_text:
								res.data.order_confirmation_text,
							total_excl_amount: res.data.total_excl_amount,
						};
						window.localStorage.setItem(
							"orderDetails",
							JSON.stringify(saveDetails)
						);
						this.$services.helpers.notification(
							this.$t("orderedSuccessfully"),
							"success",
							this
						);
						setTimeout(() => {
							this.$store.commit("customer/CLEAR_CART");
							this.$router.replace({
								name: "orderconfirmation",
								params: { company_id: this.companyID },
								query: { status: res.data.type },
							});
						}, 1000);
					})
					.catch((err) => {
						this.check = true;
						window.localStorage.setItem("__checkrun__", true);
						loading.close();
						this.processing = false;
						this.bankIDinfo = {};
						this.$store.commit("customer/STORE_BANK_ID", null);
						if (err.response.status === 400) {
							if (err.response.data.hasOwnProperty("max_limit")) {
								window.Bus.$emit("open-max-error-message");
							} else {
								if (
									err.response.data.hasOwnProperty(
										"message"
									) &&
									err.response.data.hasOwnProperty(
										"message_swe"
									)
								) {
									if (this.GET_LOCALE === "se") {
										this.$services.helpers.notification(
											err.response.data.message_swe,
											"error",
											this
										);
									} else {
										this.$services.helpers.notification(
											err.response.data.message,
											"error",
											this
										);
									}
								} else {
									this.$services.helpers.notification(
										err.response.data.message,
										"error",
										this
									);
								}
							}
						} else if (err.response.status === 401) {
							this.$store.commit("auth/CLEAR_AUTH_CUS", null);
							window.Bus.$emit("sign-out");
						} else if (err.response.status === 403) {
							this.$services.helpers.notification(
								err.response.data.error,
								"error",
								this
							);
						}
					});
			}
		},
		runAutoPrefilled() {
			if (this.GET_BILLING_INFO && this.GET_BILLING_INFO[0]) {
				this.delivery.name =
					this.cleanData(this.GET_BILLING_INFO[0].delivery_name) ===
					null
						? ""
						: this.cleanData(
								this.GET_BILLING_INFO[0].delivery_name
						  );
				this.delivery.city =
					this.cleanData(this.GET_BILLING_INFO[0].delivery_city) ===
					null
						? ""
						: this.cleanData(
								this.GET_BILLING_INFO[0].delivery_city
						  );
				this.delivery.address =
					this.cleanData(
						this.GET_BILLING_INFO[0].delivery_address1
					) === null
						? ""
						: this.cleanData(
								this.GET_BILLING_INFO[0].delivery_address1
						  );
				this.delivery.country =
					this.cleanData(
						this.GET_BILLING_INFO[0].delivery_country
					) === null
						? "SE"
						: this.cleanData(
								this.GET_BILLING_INFO[0].delivery_country
						  );
				this.delivery.zipCode =
					this.cleanData(
						this.GET_BILLING_INFO[0].delivery_zip_code
					) === null
						? ""
						: this.cleanData(
								this.GET_BILLING_INFO[0].delivery_zip_code
						  );
				this.delivery.email =
					this.cleanData(this.GET_BILLING_INFO[0].email) === null
						? ""
						: this.cleanData(this.GET_BILLING_INFO[0].email);
				this.delivery.reference =
					this.GET_BILLING_INFO[0].our_reference === null
						? ""
						: this.cleanData(
								this.GET_BILLING_INFO[0].our_reference
						  );
			}
		},
		prefillBillingInfo(e) {
			if (this.useBilling && this.GET_BILLING_INFO[0]) {
				this.delivery.name =
					this.GET_BILLING_INFO[0].customer_name === null
						? ""
						: this.cleanData(
								this.GET_BILLING_INFO[0].customer_name
						  );
				this.delivery.address =
					this.GET_BILLING_INFO[0].address === null
						? ""
						: this.cleanData(this.GET_BILLING_INFO[0].address);
				this.delivery.city =
					this.GET_BILLING_INFO[0].city === null
						? ""
						: this.cleanData(this.GET_BILLING_INFO[0].city);
				this.delivery.zipCode =
					this.GET_BILLING_INFO[0].zip_code === null
						? ""
						: this.cleanData(this.GET_BILLING_INFO[0].zip_code);
			} else {
				this.runAutoPrefilled();
			}
		},
		getCustomerDeliveryAddress() {
			this.locationList = [];
			this.$store
				.dispatch("customer/getCustomerDeliveryAddress", {
					URL: `?company_id=${this.companyID}&is_paging=false`,
				})
				.then((res) => {
					this.locationList = res.data.data.results;
					// check if there is default address and prefill
					let default_address = this.locationList.filter(
						(item) => item.is_default_address === true
					);
					if (default_address.length > 0) {
						let obj = default_address[0];
						this.delivery.name = this.cleanData(obj.location_name);
						this.delivery.city = this.cleanData(obj.city);
						this.delivery.address = this.cleanData(
							obj.street_address
						);
						this.delivery.country = this.cleanData(obj.country);
						this.delivery.zipCode = this.cleanData(obj.zip_code);
					} else {
						this.runAutoPrefilled();
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_CUS", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		autoFillBillingInfo() {
			if (this.searchName !== "") {
				let deliveryIndex = this.locationList.findIndex(
					(item) => item.id === parseInt(this.searchName)
				);
				if (deliveryIndex > -1) {
					let obj = this.locationList[deliveryIndex];
					this.delivery.name = this.cleanData(obj.location_name);
					this.delivery.city = this.cleanData(obj.city);
					this.delivery.address = this.cleanData(obj.street_address);
					this.delivery.country = this.cleanData(obj.country);
					this.delivery.zipCode = this.cleanData(obj.zip_code);
				} else {
					this.delivery.name = "";
					this.delivery.city = "";
					this.delivery.address = "";
					this.delivery.country = "";
					this.delivery.zipCode = "";
				}
			} else {
				this.delivery.name = "";
				this.delivery.city = "";
				this.delivery.address = "";
				this.delivery.country = "";
				this.delivery.zipCode = "";
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.override-pt {
	padding-top: 1.2rem !important;
}

.override-pb {
	padding-bottom: unset !important;
}

.custom-rem {
	padding: 5rem;
}

.form-group {
	margin-bottom: 0.5rem !important;
}

.form-control,
.form-select {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: 0.175rem 0.75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.total-summary {
	border-bottom: 1px solid #ddd;
	padding: 7px 0;
}

.el-loading-mask {
	.el-loading-spinner {
		display: unset !important;
	}
}

.el-check-out-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 9999999999999999999999;
}

.eid-cancel-btn {
	margin-top: 11px !important;
	padding: 6px !important;
}

.font-15 {
	font-size: 15px;
}

.select-address-div {
	text-align: right;
	position: relative;
	top: 3px;
}

.select-address {
	font-weight: 600;
}

@media only screen and (max-width: 640px) {
	.select-address-div {
		text-align: left !important;
		position: relative;
		top: 3px;
		margin-top: 5px;
	}
}
</style>
