<template>
	<div
		class="flex justify-between align-items-center dm-sans-font border-b min-w-[600px]"
	>
		<div class="col-8 flex justify-between align-items-er row">
			<div class="col-6">
				<router-link
					:to="{
						name: 'single-product',
						params: {
							company_id: product?.company,
							product_id: product?.id,
						},
					}"
					target="_blank"
				>
					<img
						class="w-24"
						:src="product.images[0].image"
						alt=""
						v-if="product.images.length > 0"
					/>
				</router-link>
			</div>
			<div class="flex flex-col pl-1 col-6">
				<p class="cp-desc font-semibold">{{ product.article_number }}</p>
				<span
					class="cp-desc"
					style="font-size: 13px !important"
					>{{ product.description }}</span
				><br />
				<span class="cp-price"
					>{{ calculatePrice(product) }} x {{ product.qty }}</span
				>

				<span
					v-if="
						product?.has_discount &&
						Number(product?.discount_value) > 0
					"
					class="font-black !text-red-500 text-[.8rem] font-bold"
					>{{ $t("discounted") }}
					{{
						convertDiscountValueToPercentage(
							product?.discount_value,
							product?.discount_type,
							product?.sales_price
						)
					}}&nbsp;%</span
				>
				<br />
				<small
					class="cp-price"
					style="
						font-size: 12px !important;
						color: #2631bf !important;
					"
					v-if="
						product.allow_custom_row_text === true &&
						product.hasOwnProperty('extra_item')
					"
				>
					<p
						style="font-size: 12px !important"
						v-if="product.hasOwnProperty('extra_item')"
						class="text-xs font-semibold"
						v-for="(item, key) in product.extra_item"
						:key="key"
					>
						{{ item.item }}
					</p>
				</small>
			</div>
		</div>

		<div class="col-4 flex space-x-2">
			<div class="flex gap-4 w-full items-center">
				<p class="!text-[1rem] !text-blue-900 font-bold">
					{{ calculatePrice(product) }}:-
				</p>
				<input-number @onchange="handleChange" :num="num" />
			</div>
			<div>
				<span class="cursor-pointer">
					<span
						@click="RemoveFromCart(product)"
						class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer"
					>
						<img
							src="./../../../assets/images/icons/delete.png"
							alt="delete"
						/>
					</span>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import inputNumber from "./inputNumber";
import { convertDiscountValueToPercentage } from "../../../utils/utils";

export default {
	name: "checkout-component",
	components: { inputNumber },
	props: {
		product: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			imageBaseServerURL: "https://api.b2bportalen.se/media/",
			toggleForm: false,
			qty: this.product.qty,
			num: this.product.qty,
			convertDiscountValueToPercentage,
		};
	},
	watch: {
		num(newVal, oldVal) {
			if (newVal > oldVal) {
				this.$store.commit("customer/UPDATE_CART_QUANTITY", {
					product: this.product,
					qty: this.num,
				});
			} else {
				this.$store.commit("customer/REMOVE_CART_PRODUCT", {
					product: this.product,
					remove: true,
					qty: this.num,
				});
			}
			// this.prevNum = oldVal
		},
	},
	methods: {
		toggleQtyForm() {
			this.toggleForm = true;
		},
		applyQty() {
			// console.log('checking now')
			if (parseInt(this.qty) > 0 && parseInt(this.qty) <= 10) {
				// console.log(this.qty)
				this.$store.commit("customer/ADD_CART_PRODUCT", {
					product: this.product,
					qty: this.qty,
				});
				this.toggleForm = !this.toggleForm;
			} else if (this.qty === 0) {
				// console.log(this.qty)
				this.$store.commit("customer/REMOVE_CART_PRODUCT", {
					product: this.product,
					remove: true,
					qty: this.qty,
				});
				this.toggleForm = !this.toggleForm;
			}
		},
		hasDiscount() {
			let currentDate = moment().format("YYYY-MM-DD");
			if (
				this.product.scheduled_campaign_start_date !== null &&
				this.product.scheduled_campaign_end_date !== null &&
				this.product.scheduled_campaign_start_date !== "" &&
				this.product.scheduled_campaign_end_date !== ""
			) {
				let startDate = moment(
					this.product.scheduled_campaign_start_date
				).format("YYYY-MM-DD");
				let endDate = moment(
					this.product.scheduled_campaign_end_date
				).format("YYYY-MM-DD");
				return !!(currentDate >= startDate && currentDate <= endDate);
			} else {
				return false;
			}
		},
		RemoveFromCart(product) {
			this.$store.commit("customer/REMOVE_CART_PRODUCT", {
				product: product,
			});
		},
		handleChange(e) {
			if (e === undefined) {
				this.num = 0;
			} else {
				this.num = e;
			}
		},
		calculatePrice(product) {
			// let price = product.sales_price;
			// if (product.hasOwnProperty("apply_discount")) {
			// 	if (product.apply_discount === true) {
			// 		price = product.discounted_price;
			// 	}
			// }
			return product?.discounted_price || product?.sales_price;
		},
		getExtraItem(items) {
			if (items) {
				if (Array.isArray(items)) {
					let itemName = "";
					items.map((item) => {
						itemName += `${item.item},`;
					});
					return itemName;
				} else {
					return "";
				}
			} else {
				return "";
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.el-input-number--mini {
	width: 95px !important;
}
</style>
